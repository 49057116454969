import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconDivider from "./IconDivider.js";
//import '../css/styles.css';

function About() {
  return (
    <section className="page-section bg-primary text-white mb-0" id="about">
      <Container>
        <h2 className="page-section-heading text-center text-uppercase text-white">
          About
        </h2>
        <IconDivider />
        <Row>
          <Col lg={4} className="ms-auto">
            <p className="lead">
              I love developing software and websites. Recently, I'm coding with
              Python and studying machine learning. In the past, I've worked on
              educational websites, games, business or scientific software, and
              a programmer toolkit.
            </p>
          </Col>
          <Col lg={4} className="me-auto">
            <p className="lead">
              I have experience using a variety of languages, such as Python,
              JavaScript, HTML5, CSS3, PHP, SQL, and C++. I graduated from
              Caltech, with a BS in Engineering and Applied Science, Computer
              Science emphasis.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;
