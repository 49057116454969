import Container from 'react-bootstrap/Container';
import IconDivider from "./IconDivider.js";
import '../css/styles.css';

function Masthead() {
  return (
    <header className="masthead bg-primary text-white text-center">
      <Container className="d-flex align-items-center flex-column">
        {/* Masthead Avatar Image */}
        <img
          className="masthead-avatar mb-5"
          src="assets/img/avataaars.svg"
          alt="Roanna Victorio avatar"
        />
        {/* Masthead Heading */}
        <h1 className="masthead-heading text-uppercase mb-0">Roanna Victorio</h1>
        <IconDivider />
        <p className="masthead-subheading font-weight-light mb-0">
          Software Developer
        </p>
      </Container>
    </header>
  );
}

export default Masthead;
