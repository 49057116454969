import "../css/styles.css";

function IconDivider() {
  return (
    <div className="divider-custom text-white">
      <div className="divider-custom-line"></div>
      <div className="divider-custom-icon">
        <i className="fas fa-volleyball-ball"></i>
      </div>
      <div className="divider-custom-line"></div>
    </div>
  );
}

export default IconDivider;
