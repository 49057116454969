import PortfolioModal from "./PortfolioModal.js";
import "../css/styles.css";

function PortfolioModals({showModal}) {
  return (
    <>
      <PortfolioModal
        id="portfolioModal1"
        headerTitle="satcrunch.com"
        pText="Web Developer, Webmaster, and Founder of satcrunch.com Test
                    Prep<br />Continually improving website and experimenting
                    with new technologies. Coded using Vim, JavaScript, PHP,
                    MySQL, CSS3. Went through several iterations from HTML4 to
                    XHTML to HTML5. Today, it features a custom WordPress child
                    theme and mobile-friendly responsive design. Created,
                    programmed, and tested every webpage. Webpages include vocab
                    games and activities (word search, hangman, glitterati
                    words), math question of the day, famous quote randomizer,
                    polls, contact form, site search, news pages (removed), site
                    map, legal, error, and admin pages. Wrote tools for
                    maintaining the website in JavaScript and PHP. Tested on
                    various devices: Android phone, iPhone, iPad, desktop
                    Chrome, Firefox, Opera, and Internet Explorer. Wrote 300 SAT
                    math problems entered into MySQL database. Researched,
                    wrote, and posted content for WordPress blog and social
                    media (Facebook, Google+, Twitter, Pinterest, YouTube).
                    Edited images using Photoshop. Improved SEO. Integrated 3rd
                    party code (odds of getting into college, RevolverMaps,
                    Google Ads, Amazon bookstore, Google Analytics)."
        imageSrc="assets/img/portfolio/satcrunch.png"
        altText="satcrunch.com website for SAT Crunch Test Prep"
        showModal={showModal}
      />
      <PortfolioModal
        id="portfolioModal2"
        headerTitle="Dr. Brain Online Game"
        pText="Network and Game Programmer, 'Dr. Brain Online'<br />
                    Designed and implemented a persistent multi-player online
                    world, client-server networking, database, and item and
                    puzzle mechanics code. Used Winsock, Microsoft Visual C++,
                    and Ace (an in-house multimedia engine language). Also
                    ported a JumpStart game module written in C++ to Java for
                    the company web site, and maintained server code for
                    KnowledgeLand, a web-based multi-player 3D virtual world
                    with educational games."
        imageSrc="assets/img/portfolio/DrBrainGames.png"
        altText="Dr. Brain Online Game"
        showModal={showModal}
      />
      <PortfolioModal
        id="portfolioModal3"
        headerTitle="Steven Spielberg's Director Chair"
        pText="Lead Programmer, 'Steven Spielberg’s Director’s Chair'<br />
                    Programmed a 3 CD game, where players edit a script, select
                    movie shots, edit video clips, add sound and music effects
                    to create their own movie. Coding was done in Ace and used
                    QuickTime movies. Helped design functional and technical
                    specifications for the game and schedule programming tasks."
        imageSrc="assets/img/portfolio/Steven_Spielberg_Directors_Chair.png"
        altText="Steven Spielberg's Director Chair"
        showModal={showModal}
      />
      <PortfolioModal
        id="portfolioModal4"
        headerTitle="Omniva Policy Systems"
        pText="Contract Programmer<br />
                    Wrote a web-based email client using Java servlets, HTML,
                    Apache Web server, JRun servlet engine, Sybase database, and
                    Sendmail on a Linux box. The email client could compose,
                    encrypt, track, delete (even after sending), and read
                    messages. Also helped design and write an email policy
                    management system using JavaScript."
        imageSrc="assets/img/portfolio/emailPolicy.png"
        altText="Omniva Policy Systems"
        showModal={showModal}
      />
      <PortfolioModal
        id="portfolioModal5"
        headerTitle="Evolution Robotics"
        pText="Software Engineer<br />
                    Wrote distributed robot control objects for the drive system
                    and sensor input using C++ and CORBA, wrote low-level code
                    to grab and process data from I/O boards controlling robot
                    sensors and motors, modified MFC robot control application
                    to process commands from a website, and researched video
                    streaming and database technologies."
        imageSrc="assets/img/portfolio/Evolution_robotics_logo.svg"
        altText="Evolution Robotics"
        showModal={showModal}
      />
      <PortfolioModal
        id="portfolioModal6"
        headerTitle="Oracle"
        pText="Software Developer, Toolkit Group<br />Programmed Mac OS
                    graphical user interface components for an operating system
                    independent toolkit used by Oracle application developers.
                    Also made a Klax arcade demo game."
        imageSrc="assets/img/portfolio/oracle.png"
        altText="Oracle Corporation"
        showModal={showModal}
      />
      <PortfolioModal
        id="portfolioModal7"
        headerTitle="MSC Software"
        pText="Software Developer, Technical Interface Div.<br />Designed
                    and coded a spreadsheet-like preprocessor for MSC/XL (a
                    graphical modeling product). Used C, X-windows, Motif, and
                    UNIX."
        imageSrc="assets/img/portfolio/MSC_Software.png"
        altText="MSC Software"
        showModal={showModal}
      />
      <PortfolioModal
        id="portfolioModal8"
        headerTitle="Project SEED at Caltech"
        pText="Project SEED at Caltech: Designed and programmed a hands-on
                    corn plant anatomy and physiology simulation for 4th
                    graders."
        imageSrc="assets/img/portfolio/corn_plant.png"
        altText="Project SEED"
        showModal={showModal}
      />
      <PortfolioModal
        id="portfolioModal9"
        headerTitle="Space Adventure 2"
        pText="Lead Programmer, 'Space Adventure II'<br />Programmed an
                    astronomy reference product with 400 articles, a find the
                    constellations game, moon phases and cause of the seasons
                    simulations, movie theater, a 3D virtual museum, and 7 other
                    modules. Used KAV, Perl, 3D-Studio modeler / render, and
                    other multimedia tools."
        imageSrc="assets/img/portfolio/SpaceAdventure2.png"
        altText="Space Adventure 2 Game"
        showModal={showModal}
      />
    </>
  );
}

export default PortfolioModals;
