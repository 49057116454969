import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import IconDivider from "./IconDivider.js";
import '../css/styles.css';

function PortfolioModal({id, headerTitle, pText, imageSrc, altText, showModal }) {
  const [show, setShow] = useState(id === showModal);
  useEffect(() => {
    setShow(id === showModal);
  }, [showModal]);

  return (
    <Modal
      className="portfolio-modal"
      show={show}
      onHide={()=>setShow(false)}
      size="xl"
      aria-labelledby={id}
      centered
      animation
    >
      <Modal.Dialog className="modal-xl">
        <div className="modal-content">
          <Modal.Header className="border-0" closeButton>
          </Modal.Header>
          <Modal.Body className="text-center pb-5">
            <Container>
              <Row className="justify-content-center">
                <Col lg={8}>
                  {/*  Portfolio Modal - Title  */}
                  <h2
                    className="portfolio-modal-title text-secondary text-uppercase mb-0"
                  >
                    { headerTitle }
                  </h2>
                  <IconDivider />
                  {/*  Portfolio Modal - Image  */}
                  <Image
                    className="mb-5"
                    src={imageSrc}
                    alt={altText}
                    rounded
                    fluid
                  />
                  {/*  Portfolio Modal - Text  */}
                  <p className="mb-4">{pText}
                  </p>
                  <Button variant="primary" onClick={()=>setShow(false)}>
                    <i className="fas fa-times fa-fw"></i>
                    Close Window
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </div>
      </Modal.Dialog>
    </Modal>
  );
}

export default PortfolioModal;
