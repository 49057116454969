import Container from 'react-bootstrap/Container';
import '../css/styles.css';

function Copyright() {
  return (
    <div className="copyright py-4 text-center text-white">
      <Container>
        <small>Copyright &copy; Roanna Victorio 2024</small>
      </Container>
    </div>
  );
}

export default Copyright;
