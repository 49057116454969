import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import "../css/styles.css";

function PortfolioItem({ imageSrc, altText, dataBsTarget, setShowModal}) {
  //console.log(`PortfolioItem:   imageSrc is ${imageSrc}`);

  return (
    <Col md={6} lg={4} className="mb-5">
      <div
        className="portfolio-item mx-auto"
        onClick={() => {
          setShowModal(dataBsTarget);
          //console.log(`PortfolioItem: onClick: dataBsTarget is ${dataBsTarget}`);   // #portfolioModal1
        }}
      >
        <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
          <div className="portfolio-item-caption-content text-center text-white">
            <i className="fas fa-plus fa-3x"></i>
          </div>
        </div>
        <Image src={imageSrc} alt={altText} fluid />
      </div>
    </Col>
  );
}

export default PortfolioItem;
