import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../css/styles.css";

function Footer() {
  return (
    <footer className="footer text-center" id="footer-contact">
      <Container>
        <Row>
          {/*  Footer Email  */}
          <Col lg={4} className="mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">Email</h4>
            <p className="lead mb-0">roanna.victorio@gmail.com</p>
          </Col>
          {/*  Footer Social Icons */}
          <Col lg={4} className="mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">Social</h4>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="https://github.com/roannav"
            >
              <i className="fab fa-fw fa-github"></i>
            </a>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="https://www.linkedin.com/in/roanna-v"
            >
              <i className="fab fa-fw fa-linkedin-in"></i>
            </a>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="https://twitter.com/roannav"
            >
              <i className="fab fa-fw fa-twitter"></i>
            </a>
          </Col>
          {/*  Footer Location */}
          <Col lg={4} >
            <h4 className="text-uppercase mb-4">Location</h4>
            <p className="lead mb-0">Irvine, CA, USA</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
