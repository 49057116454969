import {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Nav from "./components/Nav.js";
import Masthead from "./components/Masthead.js";
import PortfolioItem from "./components/PortfolioItem.js";
import IconDivider from "./components/IconDivider.js";
import About from "./components/About.js";
import Footer from "./components/Footer.js";
import Copyright from "./components/Copyright.js";
import PortfolioModals from "./components/PortfolioModals.js";

function App() {
  const [showModal, setShowModal] = useState('');

  return (
    <div>
      <Nav />
      <Masthead />
      {/*  Portfolio Section  */}
      <section className="page-section portfolio" id="portfolio">
        <Container>
          {/*  Portfolio Section Heading  */}
          <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">
            Projects
          </h2>
          <IconDivider />
          {/*  Portfolio Grid Items  */}
          <Row className="justify-content-center">
            <PortfolioItem
              imageSrc="/assets/img/portfolio/satcrunch.png"
              altText="satcrunch.com website for SAT Crunch Test Prep"
              dataBsTarget="portfolioModal1"
              setShowModal={setShowModal}
            />
            <PortfolioItem
              imageSrc="/assets/img/portfolio/DrBrainGames.png"
              altText="Dr. Brain Games"
              dataBsTarget="portfolioModal2"
              setShowModal={setShowModal}
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/Steven_Spielberg_Directors_Chair.png"
              altText="Steven Spielberg Directors Chair"
              dataBsTarget="portfolioModal3"
              setShowModal={setShowModal}
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/emailPolicy.png"
              altText="Omniva Policy Systems"
              dataBsTarget="portfolioModal4"
              setShowModal={setShowModal}
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/Evolution_robotics_logo900.png"
              altText="Evolution Robotics"
              dataBsTarget="portfolioModal5"
              setShowModal={setShowModal}
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/oracle.png"
              altText="Oracle Database"
              dataBsTarget="portfolioModal6"
              setShowModal={setShowModal}
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/MSC_Software.png"
              altText="MSC Software"
              dataBsTarget="portfolioModal7"
              setShowModal={setShowModal}
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/Caltech900.png"
              altText="Caltech Project SEED"
              dataBsTarget="portfolioModal8"
              setShowModal={setShowModal}
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/SpaceAdventure2.png"
              altText="Space Adventure 2 Game"
              dataBsTarget="portfolioModal9"
              setShowModal={setShowModal}
            />
          </Row>
        </Container>
      </section>
      <About />
      <Footer />
      <Copyright />
      <PortfolioModals showModal={showModal} />
    </div>
  );
}
/*
          <!-- Portfolio Item 6-->
          <Col md={6} lg={4} class="mb-5">
          <!-- Portfolio Item 7-->
          <Col md={6} lg={4} class="mb-5 mb-lg-0">
          <!-- Portfolio Item 8-->
          <Col md={6} lg={4} class="mb-5 mb-md-0">
          <!-- Portfolio Item 9-->
          <Col md={6} lg={4} >
          */

export default App;
